import EncryptUtility from "./encrypt-utility";
import AddFavourite from "./addFav";
import breadcrumbComp from "../../common/breadcrumb-comp";
import PrinterDetails from "../../common/printer-details";
import moment from "moment";
import utilityService from "./utility-service";
export default {
  data() {
    return {
      refresh: true,
      backSlash: true,
      actionList: [],
      mainPage: "",
      subPage: "",
      pageUrl: "",
      favouriteList: [],
      showAdd: false,
      userId: EncryptUtility.localStorageDecrypt("userID"),
      isPrinter: true,
      unitIdentifier: "",
      validatedUnitIdent: false,
      isFormValid: false,
      unitIdentifyValid: false,
      btnName: "",
      calcBtnName: "",
      showNextBtns: false,
      disableChangeButton: true,
      showChangeButton: false,
      mainKey: "",
      disableWarrantyEndDate: true,
      showWarrantyEndDate: false,
      showStartDate: true,
      sDate: "",
      disableCalculateButton: true,
      showCalculateButton: false,
      showDivider: false,
      currWarrantyStatus: "",
      warrantyEndDate: "",
      primaryName: EncryptUtility.localStorageDecrypt("primaryName"),
      max50Rule: [(v) => (v || "").length <= 50 || "Max Length of 50 character", (v) => !!v || "Field is required"],
      max5Rule: [(v) => (v || "").length <= 5 || "Max Length of 5 character", (v) => !!v || "Field is required"],
      maxStartDate: new Date().toISOString().split("T")[0],
    };
  },
  async created() {
    this.route = this.$route.path.replace("/", "");
    let data = await AddFavourite.getPageAction(this.userId, this.route);
    this.actionList = data.actionList;
    this.mainPage = data.mainPage;
    this.favouriteList = data.favouriteList;
    this.pageUrl = data.pageUrl;
    this.subPage = data.subPage;
    this.showAdd = data.showAdd;
  },
  computed: {
    computedDateFormattedMomentjs() {
      return this.sDate ? moment(String(this.sDate)).format("MM/DD/YYYY") : "";
    },
  },
  mounted() {
    this.$refs.refUnitIdentifier.focus();
    if (EncryptUtility.localStorageDecrypt("primaryLabelSize") !== null && EncryptUtility.localStorageDecrypt("primaryLabelSize") !== undefined) {
      this.primaryLabelSize = EncryptUtility.localStorageDecrypt("primaryLabelSize");
      if (
        EncryptUtility.localStorageDecrypt("primaryPrinterType") !== null &&
        EncryptUtility.localStorageDecrypt("primaryPrinterType") !== undefined
      ) {
        this.primaryPrinterType = EncryptUtility.localStorageDecrypt("primaryPrinterType");
      }
    }
  },
  methods: {
    //To add or remove Favourite
    addFav() {
      this.showAdd = !this.showAdd;
      AddFavourite.add_remove_Favourite(this.showAdd, this.userId, this.route);
    },
    //Reset Function
    resetFunction() {
      this.unitIdentifier = "";
      this.validatedUnitIdent = false;
      this.$refs.UnitIdentifierForm.resetValidation();
      this.isFormValid = false;
      this.btnName = "";
      this.calcBtnName = "";
      this.showNextBtns = false;
      this.mainKey = "";
      this.disableChangeButton = true;
      this.showChangeButton = false;
      this.disableWarrantyEndDate = true;
      this.showWarrantyEndDate = false;
      this.showStartDate = false;
      this.disableCalculateButton = true;
      this.showCalculateButton = false;
      this.showDivider = false;
      this.currWarrantyStatus = "";
      this.warrantyEndDate = "";
      this.$nextTick(() => {
        this.showStartDate = true;
        this.sDate = null;
      });
      this.$refs.refUnitIdentifier.focus();
    },
    //Searching the Identifier
    async searchIdentifer() {
      if (this.$refs.UnitIdentifierForm.validate()) {
        let warrDate = moment(new Date()).format("YYYY-MM-DD");
        let searchUnitIdentifier = {
          pass: 1,
          unit: this.unitIdentifier,
          main_key: 0,
          UserId: parseInt(this.userId),
          lbl_size: this.primaryLabelSize,
          printer_type: this.primaryPrinterType,
          warr_end_date: `${warrDate}T00:00:00.000Z`,
        };
        let showMessage = false;
        let searchData = await utilityService.warrantyChange("post", searchUnitIdentifier, showMessage);
        console.log("searchData",searchData);
        if (searchData !== undefined) {
          if (searchData.message !== "NA") {
            this.btnName = searchData.Info[0].btn_label;
            this.mainKey = searchData.Info[0].main_key;
            this.currWarrantyStatus = searchData.Info[0].current_status;
            if (searchData.Info[0].current_warr_end_date == "1900-01-01T00:00:00") {
              this.warrantyEndDate = "N/A";
            } else if (searchData.Info[0].current_warr_end_date !== "") {
              this.warrantyEndDate = searchData.Info[0].current_warr_end_date;
              this.warrantyEndDate = moment(this.warrantyEndDate).format("MM/DD/YYYY");
            }
            this.showNextBtns = true;
            this.isFormValid = true;
            this.validatedUnitIdent = true;
            this.showDivider = true;
          }
        }
      }
    },
    //On Change click event
    async onChangeClick() {
      let warrDate = moment(new Date()).format("YYYY-MM-DD");
      let changeUnitIdentifier = {
        pass: 2,
        unit: this.unitIdentifier,
        main_key: parseInt(this.mainKey),
        UserId: parseInt(this.userId),
        lbl_size: this.primaryLabelSize,
        printer_type: this.primaryPrinterType,
        warr_end_date: `${warrDate}T00:00:00.000Z`,
      };
      let showMessage = true;
      let searchData = await utilityService.warrantyChange("post", changeUnitIdentifier, showMessage);
      if (searchData !== undefined) {
        if (searchData.message !== "NA") {
          if (this.primaryName !== null && this.primaryName !== undefined) {
            PrinterDetails.printLabelData(searchData.Info[0].label, this.primaryName);
          }
          this.resetFunction();
        }
      }
    },
    //Force Warranty Status
    forceWarrantyStatus() {
      this.showNextBtns = true;
      this.disableChangeButton = false;
      this.showChangeButton = true;
      this.showWarrantyEndDate = false;
      this.sDate = null;
      this.showCalculateButton = false;
    },
    //Calculate Warranty By End Date
    calcWarrantyEndDate() {
      this.disableWarrantyEndDate = false;
      this.showWarrantyEndDate = true;
      this.showNextBtns = true;
      this.showChangeButton = false;
      this.$refs.dateCheckForm.resetValidation();
    },
    //Warranty Calculate
    async calculateWarranty() {
      if (this.$refs.dateCheckForm.validate()) {
        let changeUnitIdentifier = {
          pass: 3,
          unit: this.unitIdentifier,
          main_key: parseInt(this.mainKey),
          UserId: parseInt(this.userId),
          lbl_size: this.primaryLabelSize,
          printer_type: this.primaryPrinterType,
          warr_end_date: `${this.sDate}T00:00:00.000Z`,
        };
        let showMessage = true;
        let searchData = await utilityService.warrantyChange("post", changeUnitIdentifier, showMessage);
        if (searchData !== undefined) {
          if (searchData.message !== "NA") {
            this.disableWarrantyEndDate = true;
            this.disableCalculateButton = false;
            this.showCalculateButton = true;
            this.calcBtnName = searchData.Info[0].btn_label;
          }
        }
      }
    },
    //On change button in calculate flow
    async onCalculateChangeClick() {
      let changeUnitIdentifier = {
        pass: 4,
        unit: this.unitIdentifier,
        main_key: parseInt(this.mainKey),
        UserId: parseInt(this.userId),
        lbl_size: this.primaryLabelSize,
        printer_type: this.primaryPrinterType,
        warr_end_date: `${this.sDate}T00:00:00.000Z`,
      };
      let showMessage = true;
      let searchData = await utilityService.warrantyChange("post", changeUnitIdentifier, showMessage);
      if (searchData !== undefined) {
        if (searchData.message !== "NA") {
          if (this.primaryName !== null && this.primaryName !== undefined) {
            PrinterDetails.printLabelData(searchData.Info[0].label, this.primaryName);
          }
          this.resetFunction();
        }
      }
    },
  },
  components: {
    breadcrumbComp,
    PrinterDetails,
  },
};
